<template>
  <div id="content">
      <div class="con_sm">
        <!-- 内容模块 -->
         <weModel v-if="index == 0"></weModel>
         <policys v-if="index == 1"></policys>
         <service v-if="index == 2"></service>
      </div>
      <p @click="$router.go(-1)">返&nbsp;&nbsp;&nbsp;&nbsp;回</p>
  </div>
</template>
<script>
import weModel from '@/components/Privacy/We.vue'
import policys from '@/components/Privacy/Policy.vue'
import service from '@/components/Privacy/Service.vue'

export default {
  components:{
      weModel,
      policys,
      service
   
  },
  data(){
    return{
      index:'',
    }
  },
  created(){
    this.index = this.$route.query.index
  }
}
</script>
<style lang="scss" scoped>
#content{
  width: 100%;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #051b8e;
  font-size: 20px;
  >p{
      width: 104px;
      height: 34px;
      border: 1px solid #9b9d78;
      border-radius: 12%;
      text-align: center;
      line-height: 34px;
      font-size: 20px;
      margin: 86px 0;
   }
  .con_sm{
    width: 1417px;
    // height: 710px;
    border: 2px solid #9b9d78;
    margin: 129px 0 0 0;
   
  }
}
</style>